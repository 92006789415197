import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Styled } from 'theme-ui';
import { globalStyles } from '../styles';
import mediaqueries from '../styles/media';
import Header from './Header';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';

const Layout = ({ children, tableOfContents, location }) => {
  const [navOpen, setNavOpen] = useState(true);

  return (
    <Styled.root>
      <Global styles={globalStyles} />
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <SiteWrapper>
        <LeftSidebar navOpen={navOpen} />
        <SiteContentWrapper>
          <SiteContent navOpen={navOpen}>{children}</SiteContent>
        </SiteContentWrapper>
        {tableOfContents.items && (
          <RightSidebar tableOfContents={tableOfContents} location={location} />
        )}
      </SiteWrapper>
    </Styled.root>
  );
};

const SiteWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  background: ${p => p.theme.colors.background};
  transition: background 0.25s var(--ease-in-out-quad);
`;

const SiteContentWrapper = styled.div`
  flex-grow: 1;
  min-width: 20rem;
`;

const SiteContent = styled.main`
  transition: 0.25s var(--ease-in-out-quad);
  opacity: ${p => (p.navOpen ? 1 : 1)};
  transform: ${p => (p.navOpen ? `translateX(16rem)` : null)};
  padding: ${p => (p.navOpen ? `4.5rem 17rem 2rem 1rem` : `4.5rem 1rem 2rem 1rem`)}
`;

/*
${mediaqueries.desktop_up`
  transform: translateX(0);
  opacity: 1;
  padding: 7rem 3rem 3rem;
  max-width: 50rem;
`};
*/

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tableOfContents: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;
