import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import useCycleColor from '../hooks/useCycleColor';
import mediaqueries from '../styles/media';
import ColorToggle from './icons/ColorToggle';
import HomeIcon from './icons/HomeIcon';
import NotesIcon from './icons/NotesIcon';
import IconButton from './icons/IconButton';
import Menu from './icons/Menu';
import LogoWrapper from './LogoWrapper';
import { Image, Text, Flex, Box } from 'rebass';
import { navigate } from "gatsby"


const Header = ({ navOpen, setNavOpen }) => {
  const { cycleColorMode } = useCycleColor();
  return (
    <StyledHeader navOpen={navOpen}>
      <HeaderSection>
        <NavIconButton>
          <IconButton
            label="Open Navigation"
            icon={<Menu />}
            size={24}
            onClick={() => {
              setNavOpen(!navOpen);
            }}
          />
        </NavIconButton>
        <Flex style={{ height: '23px',
                       justifyContent: 'center',}}>
          <Box mx={2} fontSize={1}>
            <IconButton
              label="Change Theme Color"
              icon={<NotesIcon />}
              size={20}
              onClick={() => { navigate("/notes") }}
            />
          </Box>
          <Box mx={2} fontSize={1}>
            <IconButton
              label="Change Theme Color"
              icon={<HomeIcon />}
              size={20}
              onClick={() => { navigate("/") }}
            />
          </Box>
        </Flex>
      </HeaderSection>
      <HeaderSection>
        <IconButton
          label="Change Theme Color"
          icon={<ColorToggle />}
          size={24}
          onClick={cycleColorMode}
        />
      </HeaderSection>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  z-index: 5;
  background: ${p => p.theme.colors.background};
  transition: all 0.25s var(--ease-in-out-quad);
  border-bottom: 1px solid ${p => p.theme.colors.borderColor};
  transform: ${p => (p.navOpen ? `translateX(16rem)` : null)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 1rem;
  transform: translateX(0);
`;

const NavIconButton = styled.div`
  display: flex;
  margin-right: 1rem;
`;

/*
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  z-index: 5;
  background: ${p => p.theme.colors.background};
  transition: all 0.25s var(--ease-in-out-quad);
  border-bottom: 1px solid ${p => p.theme.colors.borderColor};
  transform: ${p => (p.navOpen ? `translateX(16rem)` : null)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 1rem;
  transform: translateX(0);
`;


${mediaqueries.desktop_up`
  display: none;
`};
*/

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

Header.propTypes = {
  navOpen: PropTypes.bool,
  setNavOpen: PropTypes.func
};

export default Header;
