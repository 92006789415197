import { css } from '@emotion/core';

const globalStyles = css`
  /**
   * Thanks to Benjamin De Cock
   * https://gist.github.com/bendc/ac03faac0bf2aee25b49e5fd260a727d
   */
  :root {
    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

    /* light theme color */
    --light-background: #fff;
    --light-background-secondary: #eaeaea;
    --light-color: #222;
    --light-color-secondary: #999;
    --light-border-color: #dcdcdc;

    /* dark theme colors */
    --dark-background: #292a2d;
    --dark-background-secondary: #3b3d42;
    --dark-color: #a9a9b3;
    --dark-color-secondary: #73747b;
    --dark-border-color: #4a4b50;

    /* variables for js, must be the same as these in @custom-media queries */
    --phoneWidth: (max-width: 684px);
    --tabletWidth: (max-width: 900px);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  :root {
    box-sizing: border-box;
  }

  /* https://github.com/gatsbyjs/gatsby/issues/15486 */
  .gatsby-resp-image-image {
    width: 100%;
    height: 100%;
    margin: 0;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default globalStyles;
